import * as Sentry from "@sentry/browser";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import { getApolloClient } from "./lib/apollo";
import { CurrentUserQuery } from "gql/current_user.graphql";

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [new CaptureConsoleIntegration(
      {
        levels: ["error"]
      }
    )],
    environment: import.meta.env.MODE,
    ignoreErrors: [
      "sentry-ignored-error",
      "Unable to preload CSS"
    ],
    initialScope: {
      tags: { "stack.source": "front-end" }
    }
  });

  getApolloClient().query({ query: CurrentUserQuery }).then((res) => {
    Sentry.setUser({
      email: res.data.current_user.email,
      full_name: res.data.current_user.full_name
    });
  }).catch(() => {});
}
